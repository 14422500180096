@import '../../variables';

.banner {
  position: fixed;
  top: 0;
  margin-left: calc($navbar-width + 2 * $navbar-padding);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - $navbar-width - 2 * $navbar-padding);
  height: $banner-height;
  background: #d6ccff;
  border-bottom: 1px solid $color-brand-dark-blue;

  > p {
    padding: 24px;
  }
}
