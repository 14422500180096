@import '../../../../variables';

.cst-page-dashboard-top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  width: calc(100% - $navbar-width - 2 * $navbar-padding - 2 * 59px);
  background: #fff;
  padding: 32px 59px;
  z-index: 1;

  .cst-page-dashboard-active-year-bar__year-wrapper {
    display: flex;
    align-items: center;
    margin-left: 32px;
    width: 100%;

    .cst-page-dashboard-active-year-bar__year {
      margin-left: 8px;
    }
  }

  h1 {
    font-size: 24px;
  }
}

.hasBanner {
  .cst-page-dashboard-top-bar {
    margin-top: $banner-height;
  }
}
