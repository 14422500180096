@import '../../variables';

.cst-page-dashboard {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  max-width: 100%;
  min-width: 700px;

  &.withSubWarning {
    padding-top: 32px;
  }

  .cst-page-dashboard-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }

  .cst-page-dashboard-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    padding-top: 32px;
    margin-left: calc($navbar-width + 2 * $navbar-padding);
    margin-top: 76px;

    > .cst-fluid-content-wrapper {
      width: calc(100% - 118px) !important;
    }
  }

  &.hasBanner {
    .cst-page-dashboard-wrapper {
      margin-top: calc(76px + $banner-height);
    }
  }

  .cst-page-dashboard-baseline-wrapper {
    margin-bottom: 64px;
  }

  .cst-page-dashboard-active-year-bar {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
    position: fixed;
    top: 0;
    left: 106px;
    width: calc(100% - 106px);
    background: #fff;
    z-index: 9999999;

    p {
      font-size: 14px;
    }
  }
}
